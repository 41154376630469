import React from 'react'
import axios from 'axios'

export default class ResearchPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs');
      const wow = new WOW.WOW();
      wow.init();
    } catch (e) {
      console.error(e);
    }

    axios
      .get(`${process.env.API_URL}/umbraco/api/service/getresearch`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => {})
  }

  render() {
    const { data } = this.state
    let description = ''
    let photoUrl = ''

    if (data) {
      description = data.Description
      photoUrl = `${process.env.API_URL}${data.Photo2 ? data.Photo2 : data.Photo}`
    }

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Investigação</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section id="about" className="about_us section-padding">
          <div className="container">
            <div className="row">
              {/* <div className="section-title text-center">
                <h2>Sobre</h2>
                <span />
              </div> */}
              <div className="col-md-8 col-sm-12 col-xs-12">
                <div className="about_content">
                  <span dangerouslySetInnerHTML={{__html: description}} />
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="about-slide">
                  <img src={photoUrl} />
                </div>
              </div>
              {/* <div className="col-md-12">
                <div className="about_content mbtop">
                  <h1>Investigação</h1>
                  <span dangerouslySetInnerHTML={{__html: research}} />
                </div>
              </div> */}
            </div>

          </div>
        </section>
      </div>
    )
  }
}